import React, { useEffect, useState } from 'react';
import { Button } from '@hagerty/react-components';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useLocation, WindowLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

import { useByPartnerCustomerIdMembership } from 'src/api/useByPartnerCustomerIdMembership';
import { Translate } from 'src/components/Translate';
import LoadingContainer from 'src/components/LoadingContainer';
import { useAuthContext } from 'src/components/AuthProvider';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

const metadata = {
  title: 'Consent Confirmed | HDC for State Farm',
};

const resource = {
  allowedToSell: 'state-farm.consent.confirmed.allowedToSell',
  notAllowedToSell: 'state-farm.consent.confirmed.not-allowedToSell',
};

const Confirmed = () => {
  const [callOnce, setCallOnce] = useState(false);
  const { data, loading } = useByPartnerCustomerIdMembership();
  const { loading: authLoading } = useAuthContext();
  const location = useLocation() as WindowLocation<{ consentEmail: string }>;
  const { t } = useTranslation();
  const { trackView, trackInteraction } = useTrackingContext();
  const allowedToSell = data?.associatedAgent?.allowedToSell;

  useTracking('sf_consent_confirmed_view', null, true);

  useEffect(() => {
    if (trackView && data && !callOnce) {
      trackView('sf_consent_confirmed_view', null, {
        virtual_page_name: allowedToSell ? 'confirmed-can-purchase' : 'confirmed-cannot-purchase',
      });
      setCallOnce(true);
    }
  }, [trackView, data]);

  const handleClick = () => {
    trackInteraction('button', 'Continue to purchase');
    navigate(`/checkout/information/`, { state: { consentEmail: location?.state?.consentEmail } });
  };

  if (loading && authLoading)
    return (
      <div className="state-farm-loading">
        <LoadingContainer />
      </div>
    );

  return (
    <>
      <Helmet title={metadata.title} />
      <section className="state-farm-container">
        <Translate
          className="text-lead_2"
          resourceKey={allowedToSell ? resource.allowedToSell : resource.notAllowedToSell}
          useHtml
        />

        <div className="inset-m"></div>

        {allowedToSell && (
          <Button
            buttonType={'Primary'}
            children={t('state-farm.consent.continue')}
            buttonSize={'Medium'}
            onClick={handleClick}
          />
        )}
      </section>
    </>
  );
};

export default Confirmed;
